import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { HomepageComponent } from '../app/components/homepage/homepage.component';
import { NewUserAndUserAccessComponent } from '../app/components/new-user-and-user-access/new-user-and-user-access.component';
import { ContactUsComponent } from '../app/components/contact-us/contact-us.component';
import { TermsOfUseComponent } from '../app/components/terms-of-use/terms-of-use.component';
import { ProgramsComponent } from '../app/components/programs/programs.component';
import { UserGuideComponent } from '../app/components/user-guide/user-guide.component';
import { AuthenticateGuard } from '../app/authguards/authenticate.guard';
import { EpisodeOfCareComponent } from '../app/components/episode-of-care/episode-of-care.component';
import { PatientCenteredMedicalHomeComponent } from '../app/components/patient-centered-medical-home/patient-centered-medical-home.component';
import { TennstarComponent } from '../app/components/tennstar/tennstar.component';
import { HealthLinkComponent } from '../app/components/health-link/health-link.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ReportsComponent } from './components/reports/reports.component';
import { PrivacyComponent } from './components/privacy/privacy.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { ContractadminComponent } from './components/contractadmin/contractadmin.component';
import { ContractadminResultComponent } from './components/contractadmin-result/contractadmin-result.component';




const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomepageComponent },
  { path: 'oauth/callback', component: HomepageComponent },
  { path: 'new-user-and-user-access', component: NewUserAndUserAccessComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'terms-of-use', component: TermsOfUseComponent },
  { path: 'programs', component: ProgramsComponent, canActivate: [AuthenticateGuard] },
  { path: 'user-guide', component: UserGuideComponent, canActivate: [AuthenticateGuard] },
  { path: 'episode-of-care', component: EpisodeOfCareComponent, canActivate: [AuthenticateGuard] },
  { path: 'patient-centered-medical-home', component: PatientCenteredMedicalHomeComponent, canActivate: [AuthenticateGuard] },
  { path: 'health-link', component: HealthLinkComponent, canActivate: [AuthenticateGuard] },
  { path: 'tennstar', component: TennstarComponent, canActivate: [AuthenticateGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthenticateGuard] },
  { path: 'reports', component: ReportsComponent },
  { path: 'contractadmin', component: ContractadminComponent },
  { path: 'contractadmin-result', component: ContractadminResultComponent },
  { path: 'privacy', component: PrivacyComponent },
]
//

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
  // ...any other options you'd like to use
};

// { path: 'episode-of-care', component: EpisodeOfCareComponent, canActivate: [AuthenticateGuard] },
// { path: 'patient-centered-medical-home', component: PatientCenteredMedicalHomeComponent, canActivate: [AuthenticateGuard] },
// { path: 'health-link', component: HealthLinkComponent, canActivate: [AuthenticateGuard] },
// { path: 'tennstar', component: TennstarComponent, canActivate: [AuthenticateGuard] },
// { path: 'programs', component: ProgramsComponent , canActivate: [AuthenticateGuard]},

@NgModule({
  imports: [
    RouterModule.forRoot(routes, routerOptions),
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
