<div class="container">
    <div class="row ">
        <div class="col-sm-12" style="margin-top: 1rem;">
            <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);" aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a routerLink="/home" style="text-decoration:None;">Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">User Guides</li>
                </ol>
            </nav>
        </div>
    </div>
    <div class="row" style="margin-top: 3rem;">
        <div class="col-sm-6" class="user-guide-text">
            <p><img src="../../../assets/EOC.png" alt="episode" class="user-guide-height"> <a class="user-guide">Episode of Care Reports</a></p>
        </div>
        <div class="col-sm-6" class="user-guide-text">
            <p><img src="../../../assets/HL.png" alt="healthlink" class="user-guide-height"> <a class="user-guide">Health Link Reports</a></p>
        </div>
        <div class="col-sm-6" class="user-guide-text">
            <p><img src="../../../assets/PCMH.png" alt="patient" class="user-guide-height"> <a class="user-guide">Patient Centered Medical Home Reports</a></p>
        </div>
        <div class="col-sm-6" class="user-guide-text">
            <p><img src="../../../assets/tennstar.png" alt="tennstar" class="user-guide-height"> <a class="user-guide">TennStar  Reports</a></p>
        </div>
    </div>
</div>
