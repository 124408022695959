import { Component } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { AuthorizationService } from 'src/app/services/authenticate-service/authorization.service';
import { FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-client-config',
  templateUrl: './client-config.component.html',
  styleUrls: ['./client-config.component.css']
})
export class ClientConfigComponent {
  [x: string]: any;
  public previousreportcycleid:any;
  public reportcycleid:any;
  public reporttype:any;
  public duedate:any;
  public partnerValue:any;
  public show_hide_div:boolean = false;

  dtmodel: NgbDateStruct;
  

  constructor(
    public authservice: AuthorizationService, 
    public localStorage:LocalStorageService,
    private storage_token: LocalStorageService,
    private toastr: ToastrService,
    private _parserFormatter: NgbDateParserFormatter,
    private router: Router

  ) 
  {
     
  };
  ReporttypeList:any = this.authservice.getReportTypeList();

  ngAfterViewInit()
  {
      
  }

  alphanumeric_DOT_Only(event): boolean {

    var regex = new RegExp("^[a-zA-Z0-9.]+$");
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
       event.preventDefault();
       return false;
    }
    return true;
  }

  LoadDueDate()
  {
    this.duedate = this._parserFormatter.format(this.dtmodel);
  }


    validateClientConfig()
    {
      if(this.reportcycleid == undefined || this.reportcycleid=="" )
        {
          this.toastr.error(
            'Report Cycle ID: Required.'
          );
          return false;
        }

        if(this.duedate == undefined || this.duedate =="" )
          {
            this.toastr.error(
              'Report Due Date: Required.'
            );
            return false;
          }
        return true;

        

      }


  saveClientConfig()
  {
    if(this.validateClientConfig()===true)
    {
      this.authservice.SaveClientConfig(this.duedate, this.reportcycleid);
      this.toastr.success("Client Config: Request submitted Succesfully.");
      setTimeout(() => 
        {

          if(this.storage_token.get('Client_Config_Result') === true)
          {
            this.toastr.success("Client Config: Your Request Processed Succesfully.");
            this.router.navigateByUrl("/reports?program=ClientConfigResult");
          }
          else
          {
            this.toastr.success("Client Config: Unable to Save.");
          }
         
        }
       , 70000 );
    }
    else
    { 
      this.toastr.error("Unable to submit.  Please provide all Required values in Client Config.");
      
    }
  }  
}
