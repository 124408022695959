<!-- <iframe title="Bill Testing - titanic" width="1500" height="800"
    src="https://app.powerbi.com/reportEmbed?reportId=d5f77330-b5ef-4706-b15a-cde355f2408d&autoAuth=true&ctid=db05faca-c82a-4b9d-b9c5-0f64b6755421"
    frameborder="0" allowFullScreen="true"></iframe> -->

<div class="container-fluid m-0 dashboard-container">
    <div class="row dashboard-bc">
        <div class="col-sm-6 dashboard-parent" style="margin-top: 1rem;" [ngClass]="{'dashboard-parent-no-margin': !openReportsNav||!openProgramsNav}">
            <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
                aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/home">Home</a></li>
                    <li class="breadcrumb-item"  aria-current="page"><a routerLink="/reports"
                        [queryParams]="{program: this.getProgram()}"
                        >{{ this.getProgram() }}</a></li>
                    <li class="breadcrumb-item" aria-current="page">
                        <a (click)="this.reportHome()" style="cursor: pointer; color: #0d6efd;">
                            {{ this.getUrlName() }}
                            <span *ngIf="localStorage.get('user_type')=='Plan' && this.tabActive!==undefined">({{ this.getReportRoleType() }})</span>
                        </a>
                    </li>
                </ol>
            </nav>
        </div>
    </div>
    <div class="row dashboard-main">
        <app-reports [ngClass]="{'col-sm-7': openReportsNav&&openProgramsNav, 'col-sm-4': openReportsNav&&!openProgramsNav, 'app-programs-close': !openReportsNav}"></app-reports>
        <div class="d-flex collapser col-sm-1 justify-content-center"  (click)="this.toggleReportsNav()" title="Toggle Reports Navigation">
            <span class="d-flex align-self-center justify-content-center" *ngIf="openReportsNav">&#60;</span>
            <span class="d-flex align-self-center justify-content-center" *ngIf="!openReportsNav">&#62;</span>
        </div>
        <div id="reportContainer-parent" [ngClass]="{'col-sm-4': openReportsNav&&openProgramsNav, 'col-sm-7': openReportsNav&&!openProgramsNav, 'col-sm-11': !openReportsNav}">
            <div class="plan-provider-tab" *ngIf="localStorage.get('user_type')=='Plan'">
                <span (click)="this.planProviderTabToggle('Plan')" [ngClass]="{'tabActive': this.tabActive=='Plan'}">Plan</span>
                <span (click)="this.planProviderTabToggle('Provider')" [ngClass]="{'tabActive': this.tabActive=='Provider'}">Provider</span>
            </div>
            <app-action-bar *ngIf="this.localStorage.get('reportLoaded')"></app-action-bar>
            <!-- <span id="fullscreen-report-icon" (click)="this.toggleReportFullscreen()" title="Toggle Report Fullscreen"  *ngIf="this.localStorage.get('reportLoaded')">
                <img src="../../../assets/full-screen-icon.png" alt="full-screen-icon">
            </span> -->
            <div id="reportContainer"></div>
            <!-- <div id="reportContainer" *ngIf="checkProgramPermisson(); else notallowed"></div>
            <ng-template #notallowed>You are not authorized to view this report. </ng-template> -->
        </div>
    </div>
</div>
