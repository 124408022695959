import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { AuthorizationService } from 'src/app/services/authenticate-service/authorization.service';



interface IClientConfigResult {
  state: string,
  tin: string,
  provideR_NAME: string,
	eoC_ID: string,
	rpT_CYCLE_ID: string,
	rpT_CONFIG_ID: string,
	confiG_ID: string
}


@Component({
  selector: 'app-clientconfig-result',
  templateUrl: './clientconfig-result.component.html',
  styleUrls: ['./clientconfig-result.component.css']
})

 

export class ClientconfigResultComponent implements OnInit {

  public configResult:any;
  public ccReportList:any;
  public reports: IClientConfigResult[];
	page = 1;
	pageSize = 10;
	collectionSize =   10;

  ccReportCollection: any[];

  titlemodel = {
    columns: [
	 {label: 'STATE', id: 'STATE', datatype: 'text'},
	 {label: 'TIN', id: 'TIN', datatype: 'text'},
	 {label: 'PROVIDER NAME', id: 'PROVIDER_NAME', datatype: 'text'},
	 {label: 'EOC ID', id: 'EOC_ID', datatype: 'text'},
	 {label: 'REPORT CYCLE ID', id: 'RPT_CYCLE_ID', datatype: 'text'},
	 {label: 'REPORT CONFIG ID', id: 'RPT_CONFIG_ID', datatype: 'text'},
	 {label: 'CONFIG ID', id: 'CONFIG_ID', datatype: 'text'},
	]
}

  constructor(
    public authservice: AuthorizationService, 
    public localStorage:LocalStorageService,
    private storage_token: LocalStorageService
  ) 
  {
    this.configResult = this.storage_token.get('Client_Config_Result');
    
  }
  ngOnInit(): void 
  {
    this.authservice.GetClientConfigs();
    this.LoadData();
  }


  private LoadData()
  {
    setTimeout(() => 
      {
        this.ccReportList = this.authservice.getClientConfigList();
        this.collectionSize = this.ccReportList.length;
        this.refreshReport();
      }, 2000 );
  }


  refreshReport() {
    this.LoadData();
    if(this.ccReportList != undefined)
    {
        this.ccReportList = this.ccReportList.map((item, i) => ({ id: i + 1, ...item })).slice(
          (this.page - 1) * this.pageSize,
          (this.page - 1) * this.pageSize + this.pageSize,
        );
      }
    } 
}
