import { Injectable, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';
import { HttpResponseCheck } from "src/app/services/httpresponse-check/htttpResponseCheck.service"



@Injectable({
  providedIn: 'root'
})
export class ContractAdminService {
  

  constructor(
    private storage_token: LocalStorageService,
    private http: HttpClient,
    private httpResCheck: HttpResponseCheck
  ) {
    
   
  }
}
