// This file contains configs for azure dev environment

const oauthIDP = "https://identity.nonprod.onehealthcareid.com/oidc";
const hostRootURL = "https://dev.vbpintelligencehub.com";
const digitalSecurityURL = "https://apps-nonprod.uhcprovider.com/digital-security/stage";

const apiURL = hostRootURL + "/api";

export const environment = {
  production: false,
  oauthClientId: 'VBP0075942N',
  oauthLoginUrl: oauthIDP + '/authorize',
  oauthTokenUrl: oauthIDP + '/token',
  oauthUserInfoURL: oauthIDP + '/userinfo',
  oauthCallbackUrl: hostRootURL,
  callBackAuthenticated: hostRootURL + '/programs',
  digitalSecurityURL: apiURL + '/digitalsecuirtycreds',
  checkUserRegistry : apiURL + '/checkinternaluser',
  checkContractAdminUserRegistry : apiURL + '/contracts/contractadminusers',
  checkClientConfigUserRegistry : apiURL + '/contracts/clientconfigusers',
  checkUrlMapping : apiURL + '/urlmapping',
  digitalSecurityRegUrl : digitalSecurityURL,
  powerBiEmbedURL : apiURL + '/powerbiembedtoken',
  powerBiExportURL : apiURL + '/powerbiexport',
  powerBiExportStatusURL : apiURL + '/powerbiexportstatus',
  powerBiExportFileURL : apiURL + '/powerbiexportfile',
  grantTypeRefreshToken: 'refresh_token',
  grantTypeAuthorizationCode : 'authorization_code',
  ReportTypesRegistry  : apiURL + '/contracts/reporttypes/',
  ContractBasicsRegistry: apiURL + '/contracts/contract-basics/',
  CosttargetsRegistry: apiURL + '/contracts/cost-targets/',
  QualityDetailsRegistry: apiURL + '/contracts/quality-details/',
  StateRegistry  : apiURL + '/contracts/states/',
  ProviderRegistry  : apiURL + '/contracts/providers/',
  EOCRegistry  : apiURL + '/contracts/eocs/',
  MPRegistry  : apiURL + '/contracts/measurementperiods/',
  IndicatorsRegistry  : apiURL + '/contracts/indicators/',
  TINSRegistry  : apiURL + '/contracts/tins/',
  TINNamesRegistry  : apiURL + '/contracts/tinnames/',
  ClientConfigRegistry: apiURL + '/clients/configs/',
  ClientConfigReportsRegistry: apiURL + '/clients/configs/reports/',
  TiersRegistry  : apiURL + '/contracts/qmtiers/',
  ContractlistRegistry: apiURL + '/contracts/',
  ContractDataRegistry: apiURL + '/contracts/details/',
  ContractDataQTRegistry: apiURL + '/contracts/qualitydetails/',
  ContractDataCTRegistry: apiURL + '/contracts/costtargets/',

};
