import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { AuthorizationService } from '../../services/authenticate-service/authorization.service';

@Component({
  selector: 'app-contractadmin-result',
  templateUrl: './contractadmin-result.component.html',
  styleUrls: ['./contractadmin-result.component.css']
})
export class ContractadminResultComponent  {


  public contractid:any;


  constructor(
    public authservice: AuthorizationService, 
    public localStorage:LocalStorageService,
    private storage_token: LocalStorageService
  ) 
  {
    this.contractid = this.storage_token.get('CONTRACT_ID');
  };
}
