import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, JsonPipe, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from 'angular-auth-oidc-client';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LocalStorageModule } from 'angular-2-local-storage';
import { HomepageComponent } from './components/homepage/homepage.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import {AuthorizationInterceptor} from './interceptors/authorization.interceptor';
import { NewUserAndUserAccessComponent } from './components/new-user-and-user-access/new-user-and-user-access.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { ProgramsComponent } from './components/programs/programs.component';
import { UserGuideComponent } from './components/user-guide/user-guide.component';
import { EpisodeOfCareComponent } from './components/episode-of-care/episode-of-care.component';
import { PatientCenteredMedicalHomeComponent } from './components/patient-centered-medical-home/patient-centered-medical-home.component';
import { HealthLinkComponent } from './components/health-link/health-link.component';
import { TennstarComponent } from './components/tennstar/tennstar.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PowerbiService } from './services/powerbi-service/powerbi.service';
import { AuthorizationService } from './services/authenticate-service/authorization.service';
import { ActionBarComponent } from './components/action-bar/action-bar.component';
import { SessionTimeoutComponent } from './components/session-timeout/session-timeout.component';
import { ReportsComponent } from './components/reports/reports.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { ContractadminResultComponent } from './components/contractadmin-result/contractadmin-result.component';
import { NgbAccordionConfig, NgbAccordionModule, NgbAlertModule, NgbDatepickerModule, NgbModule, NgbPaginationModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { ContractadminComponent } from './components/contractadmin/contractadmin.component';
//import { TestComponent } from './components/test/test.component';
//import { QualityDetailComponent } from './components/quality-detail/quality-detail.component';
// import { CostTargetTableComponent } from './components/cost-target-table/cost-target-table.component';
// import { CostTargetComponent } from './components/cost-target/cost-target.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ClientConfigComponent } from './components/client-config/client-config.component';
import { ContractAdminService } from './services/contract-admin/contractadmin.service';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ClientconfigResultComponent } from './components/clientconfig-result/clientconfig-result.component';
import { ContractlistComponent } from './components/contractlist/contractlist.component';

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    FooterComponent,
    HeaderComponent,
    NewUserAndUserAccessComponent,
    ContactUsComponent,
    TermsOfUseComponent,
    ProgramsComponent,
    UserGuideComponent,
    EpisodeOfCareComponent,
    PatientCenteredMedicalHomeComponent,
    HealthLinkComponent,
    TennstarComponent,
    DashboardComponent,
    ActionBarComponent,
    SessionTimeoutComponent,
    ReportsComponent,
    PrivacyComponent,
    ContractadminResultComponent,
    ContractadminComponent,
    ClientConfigComponent,
    ClientconfigResultComponent,
    ContractlistComponent     
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxSpinnerModule,
    HttpClientModule,
    AuthModule,
    CommonModule,
    BrowserAnimationsModule,
    NgFor, 
    NgbModule,
    NgbTypeaheadModule, 
    NgbPaginationModule,
    FormsModule,
    NgbAccordionModule,
    Ng2SearchPipeModule,
    NgbDatepickerModule,
    LocalStorageModule.forRoot({
      prefix: 'data', 
      storageType: 'localStorage'
  }), 
  ],
  providers: [
    { 
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true,
    },
    DashboardComponent,
    PowerbiService,
    AuthorizationService,
    SessionTimeoutComponent,
    ContractAdminService,
    NgbAccordionConfig
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA,
            NO_ERRORS_SCHEMA
  ],
})
export class AppModule { }
