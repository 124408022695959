<div ngbAccordion #accordion= "ngbAccordion">
    <div ngbAccordionItem="one" [collapsed]="false">
    <h2 ngbAccordionHeader>
    <button ngbAccordionButton>Client Configuration</button>
    </h2>
        <div ngbAccordionCollapse>
           <div ngbAccordionBody>
              <div class="row">
                
                <div class="row mb-4">
                   
                       <div class="col-sm-12 col-md-6 col-lg-4">
                          <div class="form-group">
                              <label class="form-control-placeholder" for = "reportcycleid">Report Cycle ID</label>
                              <input type="text" id="reportcycleid"
                               [(ngModel)]="reportcycleid" 
                               class="form-control"
                               (keypress)="alphanumeric_DOT_Only($event)"
                               />
                          </div>
                     </div>

                     <div class="col-sm-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <label class="form-control-placeholder" for="reportduedate"
                          >Report Due Date</label
                        >
                        <div class="input-group">
                        <input
                          id="reportduedate"
                          class="form-control"
                          placeholder="yyyy-mm-dd"
                          name="reportduedate"
                          [(ngModel)] = "dtmodel"
                          ngbDatepicker
                          #reportduedate="ngbDatepicker"        
                        />
                        <button
                          class="btn btn-outline-secondary bi bi-calendar3"
                          (click)="reportduedate.toggle()"
                           (focus)="LoadDueDate()"
                           (paste)="LoadDueDate()"
                          type="button"
                        ></button>
                                                </div>
                                 </div>
                    </div>

                   
                     
              </div>

          



              </div>
                <button class="btn btn-sm btn-outline-primary me-2" style="background-color: #002060; color:#ffffff;"
                  (click)="saveClientConfig()"
                > Submit
                </button>
            </div>
        </div>
</div>
</div>

