<div *ngIf="configResult === true">
   
    <table class=".table table-striped">
        <tr>
            <th *ngFor="let header of titlemodel.columns">
                {{ header.label }}
            </th>
        </tr>
    
    
    <tr *ngFor="let item of ccReportList">
      <td>
        {{ item.state }}
      </td>
       
      <td>
        {{ item.tin }}
      </td>
       <td>
        {{ item.provideR_NAME }}
      </td>
       <td>
        {{ item.eoC_ID }}
      </td>
       <td>
        {{ item.rpT_CYCLE_ID }}
      </td>
       <td>
        {{ item.rpT_CONFIG_ID }}
      </td>
       <td>
        {{ item.confiG_ID }}
      </td>
      </tr>
      </table>

      <div class="d-flex justify-content-between p-2">
        <ngb-pagination
          [collectionSize]="collectionSize"
          [(page)]="page"
          [pageSize]="pageSize"
          (pageChange)="refreshReport()"
        >
        </ngb-pagination>
      
        <!-- <select class="form-select" style="width: auto" [(ngModel)]="pageSize"
        (ngModelChange)="refreshReport()">
          <option [ngValue]="2">2 items per page</option>
          <option [ngValue]="4">4 items per page</option>
          <option [ngValue]="6">6 items per page</option>
        </select> -->
      </div>
      
</div>