<div class="result">
    <div *ngIf="contractid > 0">
        New Contract Generated succesfully. Contract ID: {{contractid}}
      </div>
    <div *ngIf="contractid == 0 || contractid== undefined ">
        Click <a href="/reports?program=ContractAdmin">&nbsp;here&nbsp;</a> to Generate a New Contract.
    </div>
</div>

  


