import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { AuthorizationService } from '../../services/authenticate-service/authorization.service';


interface IContractAdminList {
  contract_id: string,
  client_id: string,
  tin: string,
	state: string,
	county: string,
	eoc_id: string,
  contract_stat:string,
  contract_begin:string,
  contract_end:string,
  
}


@Component({
  selector: 'app-contractlist',
  templateUrl: './contractlist.component.html',
  styleUrls: ['./contractlist.component.css']
})
export class ContractlistComponent {

  public ContractData_List:any;
  public ccReportList:any;
  public reports: IContractAdminList[];
	page = 1;
	pageSize = 25;
	collectionSize =   25;
  public cad:string="ContractAdmin";
  
  titlemodel = {
    columns: [
	 {label: 'Action', id: 'ACTION', datatype: 'text', style: { 'text-align': 'left' }},
	 {label: 'CONTRACT #', id: 'CONTRACT_ID', datatype: 'text', style: { 'text-align': 'left' }},
	 {label: 'CLIENT ID', id: 'CLIENT_ID', datatype: 'text', style: { 'text-align': 'left' }},
	 {label: 'TIN', id: 'TIN', datatype: 'text', style: { 'min-width': '5%','text-align': 'left' }},
	 {label: 'STATE', id: 'STATE', datatype: 'text', style: { 'text-align': 'left' }},
	 {label: 'COUNTY', id: 'COUNTY', datatype: 'text', style: { 'text-align': 'left' }},
	 {label: 'EOC ID', id: 'EOC_ID', datatype: 'text', style: { 'text-align': 'left' }},
	 {label: 'CONTRACT FROM', id: 'CONTRACT PERIOD FROM', datatype: 'text', style: { 'text-align': 'left' }},
	 {label: 'CONTRACT TO', id: 'CONTRACT PERIOD TO', datatype: 'text', style: { 'text-align': 'left' }},
   {label: 'STATUS', id: 'CONTRACT_STATUS', datatype: 'text', style: { 'text-align': 'left' }},
	]
}

constructor(
  public authservice: AuthorizationService, 
  public localStorage:LocalStorageService,
  private storage_token: LocalStorageService
) 
{
  
}


ngOnInit(): void 
  {
	//Actual API call 
    this.authservice.GetContractAdminList();
    this.LoadData();
  }


  private LoadData()
  {
    setTimeout(() => 
      {
        this.ccReportList = this.authservice.getcontractdataList();
        this.collectionSize = this.ccReportList.length;
        this.refreshReport();
      }, 2000 );
  }


  refreshReport() {
    this.LoadData();
    if(this.ccReportList != undefined)
    {
        this.ccReportList = this.ccReportList.map((item, i) => ({ id: i + 1, ...item })).slice(
          (this.page - 1) * this.pageSize,
          (this.page - 1) * this.pageSize + this.pageSize,
        );
      }
    } 

  


  

}
