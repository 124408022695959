

<div>
  <a routerLink="/reports" [queryParams]="{program: cad}">
    <img src="../../../assets/add.png" alt="contract admin" class="program-icon"
    style="height:50px; width:50px">
    </a>
</div>
<table class=".table table-striped">
        <tr>
            <th *ngFor="let header of titlemodel.columns" style="padding:0px">
                {{ header.label }}
            </th>
        </tr>

        <tr *ngFor="let item of ccReportList">
          <td>
            <div>
              <span>
            
                <a routerLink="/reports" [queryParams]="{program: cad, id: item.contracT_ID}">
                  <img src="../../../assets/edit.png" alt="Contract Edit" class="program-icon"
                  style="height:25px; width:25px">
                  </a>   



              </span>
              <span>&nbsp;</span>
              <span>
                <img src="../../../assets/delete.png" alt="delete"  
                  style="height:25px; width:25px" class="program-icon"> 
            </span>
          </div>

       
</td>   
     <td>
                {{ item.contracT_ID }}
    </td>
      <td>
        {{ item.clienT_ID }}
      </td>
       
      
       <td>
        {{ item.tin }}
      </td>
       <td>
        {{ item.state }}
      </td>
       <td>
        {{ item.county }}
      </td>
      <td>
        {{ item.eoC_ID }}
      </td>
   
      <td>
        {{ item.cntrcT_BEGIN }}
      </td>
      <td>
        {{ item.cntrcT_END }}
      </td>
      <td>
        {{ item.cntrcT_STAT }}
      </td>
      </tr>
      </table>

      <div class="d-flex justify-content-between p-2">
        <ngb-pagination
          [collectionSize]="collectionSize"
          [(page)]="page"
          [pageSize]="pageSize"
          (pageChange)="refreshReport()"
        >
        </ngb-pagination>
       
        
      
